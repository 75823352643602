<template>
  <div class='flex flex-col lg:flex-row justify-between items-stretch gap-x-16'
    :style='minHeightStyle'>
    <div
      class='background-style text-white py-4 px-4 lg:py-16 lg:px-8 flex-shrink-0'
      :style='sidebarBgStyle'>
      <ul class='text-xs sidebar-navigation-links flex flex-col lg:sticky lg:top-24'>
        <li class='mb-2 lg:mb-4 border border-transparent hover:border-gray-100 w-full rounded-md'>
          <router-link
            :to='{ name: "Login" }'
            class='text-lg py-2 pl-2 pr-1 uppercase opacity-90 flex flex-col-reverse lg:flex-row justify-start gap-y-2 lg:gap-y-0 lg:gap-x-2'>
            로그인하기
          </router-link>
        </li>
      </ul>
    </div>
    <div class='flex-grow relative px-4 lg:px-0'>
      <welcome-conference-banner v-if='showConferenceBanner' class='mb-8 lg:mb-12' />
      <div class='py-8 lg:py-16'>
        <h1 class='text-3xl font-bold mb-4 lg:mb-8'>{{ signupFormBoxTitle }}</h1>
        <div v-if='topHtmlContent' v-html='topHtmlContent'></div>
        <div v-for='(term, index) in terms'
          :key='`term-${index}`'
          class='text-xs border rounded-md mb-8 w-full lg:w-1/2'>
          <div class='bg-gray-50 p-4' v-html='term.text'></div>
          <label class='border-t p-4 flex flex-row justify-start items-center gap-x-0.5 text-sm'>
            <input type='checkbox' :name='`checkbox-${index}`' v-model='term.agreed'>
            <span class='px-1'>{{ term.confirmationText }}</span>
          </label>
        </div>
        <edit-form-field
          class='mb-6 w-full lg:w-96' 
          v-for='formField in signupFormFields'
          :key='formField.keyName'
          :edit-form-field='formField'
          @update-edit-form-field-value='updateSignupFormFieldValue(formField.category, formField.keyName, ...arguments)' />
        <button 
          class='px-4 py-3 hover:shadow-md uppercase border rounded-md submit-button w-full lg:w-1/2 mb-6'
          @click='submitSignup'
          :disabled='disabledSignupButton'
          :class='disabledSignupButtonClass'>
          {{ signupButtonText }}
        </button>
        <ul class='list-disc list-inside text-red-600 text-sm'>
          <div v-for='formField in signupFormFields'
            :key='formField.keyName'>
            <li v-if='!fieldValue(formField.category, formField.keyName)'>
              {{ formField.name }} 을/를 입력해주십시오.
            </li>
          </div>
          <li v-if='needToCheckPasswordConfirmation'>
            비밀번호를 확인해주십시오.
          </li>
          <li v-if='needToCheckEmailFormat'>
            이메일형식을 확인해주십시오.
          </li>
        </ul>
      </div>
      <welcome-sponsor-carousel  v-if='showSponsorCarousel'  class='max-w-4xl mt-16 lg:mt-20'/>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import cloneDeep from 'lodash/cloneDeep'
import camelCase from 'lodash/camelCase'
import { mapFields } from 'vuex-map-fields'

export default {
  name: 'Signup',
  components: {
    'edit-form-field': () => import('@/components/edit-form/EditFormField.vue'), 
    'welcome-conference-banner': () => import('@/components/welcome/WelcomeConferenceBanner.vue'),
    'welcome-sponsor-carousel': () => import('@/components/welcome/WelcomeSponsorsCarousel.vue'),
  },
  props: {
    showConferenceBanner: {
      type: Boolean,
      default: false,
    },
    showSponsorCarousel: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      isValidPhoneNumber: false,
    }
  },
  watch: {
    'termsDefault': {
      handler: function (newVal, oldVal) {
        if (newVal && newVal !== oldVal) {
          this.terms = cloneDeep(newVal)
        }
      },
      immediate: true,
      deep: true
    },
    'userDefault': {
      handler: function (newVal, oldVal) {
        if (newVal && newVal !== oldVal) {
          this.signupParams = cloneDeep(newVal)
        }
      },
      immediate: true,
      deep: true
    },
    'profileDefault': {
      handler: function (newVal, oldVal) {
        if (newVal && newVal !== oldVal) {
          this.profile = cloneDeep(newVal)
        }
      },
      immediate: true,
      deep: true
    },
    'registrationDataDefault': {
      handler: function (newVal, oldVal) {
        if (newVal && newVal !== oldVal) {
          this.registrationData = cloneDeep(newVal)
        }
      },
      immediate: true,
      deep: true
    },
    'membershipDataDefault': {
      handler: function (newVal, oldVal) {
        if (newVal && newVal !== oldVal) {
          this.membershipData = cloneDeep(newVal)
        }
      },
      immediate: true,
      deep: true
    },
  },
  computed: {
    ...mapFields('users', [
      'signupParams',
      'profile',
      'terms',
      'registrationData',
      'membershipData',
    ]),
    ...mapState('users', [
      'hasValidToken',
    ]),
    ...mapGetters('events', [
      'showingEventSignupConfigurations',
      'eventMainThemeColor',
    ]),
    minHeightStyle () {
      let minHeight = 64 // size of the navbar
      return `min-height: calc(100vh - ${minHeight}px);`
    },
    customBackgroundStyle () {
      return "background-color: #926699; background-image: url(https://webconcert-public-assets.s3.ap-northeast-2.amazonaws.com/conferences/kossoasc/sidebar_image_v2.png); background-repeat: no-repeat; background-position: top; background-size: 100% auto;"
    },
    sidebarBgStyle () {
      return (this.customBackgroundStyle) ? this.customBackgroundStyle : `background-color: ${this.eventMainThemeColor};`
    },
    signupFormBoxTitle () {
      return (this.showingEventSignupConfigurations && this.showingEventSignupConfigurations.signupFormBoxTitle) ? this.showingEventSignupConfigurations.signupFormBoxTitle : 'My Signup Details'
    },
    signupButtonText () {
      return (this.showingEventSignupConfigurations && this.showingEventSignupConfigurations.signupButtonText) ? this.showingEventSignupConfigurations.signupButtonText : 'Register & Pay Registration Fees'
    },
    topHtmlContent () {
      return (this.showingEventSignupConfigurations && this.showingEventSignupConfigurations.topHtmlContent) ? this.showingEventSignupConfigurations.topHtmlContent : ''
    },
    userDefault () {
      return (this.showingEventSignupConfigurations && this.showingEventSignupConfigurations.userDefault) ? this.showingEventSignupConfigurations.userDefault : {}
    },
    termsDefault () {
      return (this.showingEventSignupConfigurations && this.showingEventSignupConfigurations.termsDefault) ? this.showingEventSignupConfigurations.termsDefault : []
    },
    profileDefault () {
      return (this.showingEventSignupConfigurations && this.showingEventSignupConfigurations.profileDefault) ? this.showingEventSignupConfigurations.profileDefault : {}
    },
    registrationDataDefault () {
      return (this.showingEventSignupConfigurations && this.showingEventSignupConfigurations.registrationDataDefault) ? this.showingEventSignupConfigurations.registrationDataDefault : {}
    },
    membershipDataDefault () {
      return (this.showingEventSignupConfigurations && this.showingEventSignupConfigurations.membershipDataDefault) ? this.showingEventSignupConfigurations.membershipDataDefault : {}
    },
    signupFormFields () {
      return (this.showingEventSignupConfigurations && this.showingEventSignupConfigurations.signupFormFields) ?
        this.showingEventSignupConfigurations.signupFormFields.concat().sort((fieldA, fieldB) => fieldA.sortOrder - fieldB.sortOrder) : []
    },
    needToCheckEmailFormat () {
      let mailformat = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g
      return this.signupParams.email && !mailformat.test(this.signupParams.email)
    },
    needToCheckPasswordConfirmation () {
      return this.signupParams.password !== this.signupParams.passwordConfirmation
    },
    disabledSignupButton () {
      return this.signupFormFields.map(field => this.fieldValue(field.category, field.keyName)).some(value => !value) ||
        this.needToCheckPasswordConfirmation ||
        this.needToCheckEmailFormat
    },
    disabledSignupButtonClass () {
      return this.disabledSignupButton ? 'opacity-50 cursor-not-allowed' : ''
    },
  },
  methods: {
    ...mapActions('users', [
      'signup',
      'checkDuplicatedPhoneNumber',
      'patchTerms',
      'patchMembershipData',
      'createProfile',
      'checkTokenStatus',
    ]),
    fieldValue (category, keyName) {
      let arr = keyName.split('.').map(keyName => camelCase(keyName))
      let val = ''
      if (category === 'profile') {
        if (arr.length === 2) {
          val = this.profile[arr[0]][arr[1]]
        } else if (arr.length === 1) {
          if (keyName === 'address') {
            val = `${this.profile.address} (${this.profile.postalCode})`
          } else {
            val = this.profile[arr[0]]
          }
        }
      } else if (category === 'user') {
        if (arr.length === 2) {
          val = this.signupParams[arr[0]][arr[1]]
        } else if (arr.length === 1) {
          val = this.signupParams[arr[0]]
        }
      } else if (category === 'registration_data') {
        if (arr.length === 2) {
          val = this.registrationData[arr[0]][arr[1]]
        } else if (arr.length === 1) {
          val = this.registrationData[arr[0]]
        }
      } else if (category === 'membership_data') {
        if (arr.length === 2) {
          val = this.membershipData[arr[0]][arr[1]]
        } else if (arr.length === 1) {
          val = this.membershipData[arr[0]]
        }
      }
      return val
    },
    updateSignupFormFieldValue (category, keyName, value) {
      let arr = keyName.split('.').map(keyName => camelCase(keyName))
      if (category === 'profile') {
        if (arr.length === 2) {
          this.profile[arr[0]][arr[1]] = value
        } else if (arr.length === 1) {
          if (keyName === 'phone_number') {
            this.profile.phoneNumber = value.phoneNumber
            this.isValidPhoneNumber = value.isValidPhoneNumber
          } else {
            this.profile[arr[0]] = value
          }
        }
      } else if (category === 'user') {
        if (arr.length === 2) {
          this.signupParams[arr[0]][arr[1]] = value
        } else if (arr.length === 1) {
          this.signupParams[arr[0]] = value
        }
      } else if (category === 'registration_data') {
        if (arr.length === 2) {
          this.registrationData[arr[0]][arr[1]] = value
        } else if (arr.length === 1) {
          this.registrationData[arr[0]] = value
        }
      } else if (category === 'membership_data') {
        if (arr.length === 2) {
          this.membershipData[arr[0]][arr[1]] = value
        } else if (arr.length === 1) {
          this.membershipData[arr[0]] = value
        }
      }
    },
    submitSignup () {
      this.checkDuplicatedPhoneNumber(this.profile.phoneNumber).then(() => {
        this.signup().then(() => {
          let patchTerms = this.patchTerms()
          let patchMembershipData = this.patchMembershipData(this.membershipData)
          let createProfile = this.createProfile()
      
          Promise.all([patchTerms, patchMembershipData, createProfile]).then(() => {
            this.$alert('회원가입이 완료되었습니다.', {
              confirmButtonText: '확인',
              type: 'success',
              showClose: false,
            }).then(() => {
              this.$router.push({name: 'Main'})
            }).catch(() => {})
          })
        }).catch((error) => {
          if (error.response.status === 409) {
            this.$alert('이미 등록된 이메일입니다. 이메일을 확인 후 다시 등록해주세요.', {
              confirmButtonText: '확인',
              type: 'warning',
            })
          } else {
            console.error(error)
          }
        })
      }).catch(() => {
        this.$alert('이미 등록된 전화번호입니다. 전화번호를 확인 후 다시 등록해주세요.', {
          confirmButtonText: '확인',
          type: 'warning',
        })
      })
    },
  },
  created () {
    this.checkTokenStatus().then(() => {
      if (this.hasValidToken) {
        this.$router.replace({name: 'Main'}).catch(() => {})
      }
    })
  },
}
</script>

<style type='scss' scoped>
.submit-button {
  @apply text-white;
  background-color: var(--eventMainColor);
}

.background-style {
  background: transparent; 
  width: 100%;
}
.sidebar-navigation-links .router-link-active {
  @apply font-bold;
}
    
@media (min-width: 1024px) {
  .background-style {
    width: 20rem;
    height: auto;
  }
}
</style>
